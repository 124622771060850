// import { ArrowRightBold } from '@element-plus/icons';
import axios from 'axios';
import { mapActions } from 'vuex';
import { CREATE_PROPERTY_MAP_ROUTE } from '@/modules/create-property-map/routes';
import AddressMapComponent from '@/modules/properties/components/AddressMapComponent.vue';
import ManualAddressFormComponent from '@/modules/properties/components/ManualAddressFormComponent.vue';
import PostcodeFormComponent from '@/modules/properties/components/PostcodeFormComponent.vue';
import { PROPERTY_STORE } from '@/store/modules/property-details';
import { VUE_APP_EPC_API, VUE_APP_EPC_URL } from '../../../config';
export default {
    name: 'properties-page',
    components: {
        ManualAddressFormComponent,
        PostcodeFormComponent,
        AddressMapComponent,
    },
    data() {
        return {
            CREATE_PROPERTY_MAP_ROUTE,
            doneInForm: false,
            selectedAddress: null,
            buildingData: null,
            step: 1,
            isManual: false,
            isShowMap: false,
            isShowCreate: false,
            isLegalOwner: true,
            createSubmitting: false,
            isValidPostcode: false,
            hideNextButton: false
        };
    },
    // watch: {
    //   selectedAddress: {
    //     immediate: true,
    //     handler() {
    //       console.log('selectedAddress', (this as any).selectedAddress);
    //     }
    //   }
    // },
    methods: {
        ...mapActions(PROPERTY_STORE, ['setProperty', 'createProperties']),
        switchForm() {
            this.isManual = !this.isManual;
            if (this.isManual) {
                this.isShowMap = false;
                this.selectedAddress = null;
            }
        },
        async nextStep() {
            this.step = this.step + 1;
        },
        updateLegalOwner() {
            this.isLegalOwner = !this.isLegalOwner;
        },
        async createAndRouteToBilling() {
            this.createSubmitting = true;
            let property;
            try {
                const result = await axios(`
          ${VUE_APP_EPC_URL}?postcode=${this.selectedAddress.POSTCODE}&size=500`, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Basic ${VUE_APP_EPC_API}`
                    }
                });
                if (result.data.rows && result.data.rows.length > 0) {
                    const filteredUprn = result.data.rows.filter((singleData) => singleData.uprn === this.selectedAddress.UPRN);
                    let buildingData;
                    if (filteredUprn.length > 1) {
                        buildingData = filteredUprn.reduce((prevData, currData) => {
                            if (prevData && Date.parse(currData['lodgement-date']) > Date.parse(prevData['lodgement-date'])) {
                                return currData;
                            }
                            return prevData;
                        });
                    }
                    else {
                        [buildingData] = filteredUprn;
                    }
                    property = (await this.createProperties({
                        address: this.selectedAddress.ADDRESS,
                        postcode: this.selectedAddress.POSTCODE,
                        addressData: JSON.stringify(this.selectedAddress) || '{}',
                        epcData: JSON.stringify(buildingData) || '{}'
                    }));
                }
                this.createSubmitting = false;
                this.step = this.step + 1;
                // (this as any).$router.push({ name: 'property-details/home', params: { id: property.id } });
                this.$router.push({ name: 'billing', params: { id: property.id } });
            }
            catch (e) {
                this.createSubmitting = false;
            }
        },
        validPostcode(value) {
            this.isValidPostcode = value;
        },
        hideMap() {
            this.isShowMap = false;
        },
        async selectAddress(address) {
            this.selectedAddress = null;
            if (!this.isShowMap) {
                this.isShowMap = true;
            }
            if (!this.isShowCreate) {
                this.isShowCreate = true;
            }
            this.selectedAddress = {
                ...address,
                latlng: [address.LAT, address.LNG]
            };
        },
        routeToCreatePropertyMap() {
            this.$router.push({ name: CREATE_PROPERTY_MAP_ROUTE });
        }
    }
};
